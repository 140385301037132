/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
  // Button,
  Col,
  Layout,
  Row,
  Typography,
} from 'antd';
import './VmsVault.less';
import { Link } from 'react-router-dom';
import Routes from '../../Global/Routes';
// import Icon from '../../Components/Icon';
import vmsLogo from '../../Assets/Images/vms-logo.png';
import voultLogo from '../../Assets/Images/voult.png';
import playStore from '../../Assets/Images/playStore.png';
import appStore from '../../Assets/Images/appStore.png';
import vmsLogoFinal from '../../Assets/Images/vms-logo-final.png';
import vmsCloudLogo from '../../Assets/Images/VMS_Cloud_logo.png';
import vmsshopLogo from '../../Assets/Images/VMS_shop_logo.png';
import fortador from '../../Assets/Images/fortador.png';
import kuberth from '../../Assets/Images/kuberth.png';

const { Paragraph } = Typography;

function DashboardShop() {
  return (
    <Layout.Content>
      <Row>
        <Col lg={12} md={24} sm={24} xs={24} className="order-2 order-lg-1 text-center">
          <Card className="customCard customCardbg3 px-3 px-sm-5" bordered={false} bodyStyle={{ height: '100%' }}>
            <div className="d-flex flex-column justify-content-around align-items-center h-100">
              <img src={vmsLogo} width={100} alt="logo" className="mb-4" />
              <div>
                <Typography>
                  <Paragraph className="off-white">
                    Through different brands VMS brings<br />
                    unique concepts and targets enthusiasts<br />
                    who seek to enhance their motoring<br />
                    experience and maintain their vehicles in<br />
                    secure and optimal environments.
                  </Paragraph>
                </Typography>
              </div>
              <Row gutter={[16, 16]}>
                <Col sm={12} xs={24} className="d-flex align-items-center flex-column">
                  <Typography.Title className="off-white fs-14 fw-normal mb-3">Our Brands</Typography.Title>
                  <Row gutter={[16, 16]} className="brand_wrapper">
                    <Col xs={12}>
                      <img src={voultLogo} width={50} alt="logo" />
                    </Col>
                    <Col xs={12}>
                      <img src={vmsshopLogo} width={50} alt="logo" />
                    </Col>
                    <Col xs={12}>
                      <img src={vmsLogoFinal} width={50} alt="logo" />
                    </Col>
                    <Col xs={12}>
                      <img src={vmsCloudLogo} width={50} alt="logo" />
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} xs={24}>
                  <Typography.Title level={5} className="off-white fs-14 fw-normal mb-3">Brands we Represent</Typography.Title>
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <img src={fortador} width={120} alt="logo" />
                    </Col>
                    <Col xs={24} className="mt-2">
                      <img src={kuberth} width={130} alt="logo" />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div>
                <div className="d-flex align-items-center flex-wrap">
                  <Link to={Routes.home}>
                    <div className="border-0 border-radius primary r-bold h-40 bg-white d-flex justify-content-center align-items-center px-5">Home</div>
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24} className="order-1 order-lg-2">
          <Card className="customCard customCardbg2 px-3 px-sm-5" bordered={false} bodyStyle={{ height: '100%' }}>
            <div className="d-flex flex-column justify-content-around align-items-center text-center h-100">
              <img src={voultLogo} width={100} alt="logo" className="mb-4" />
              <div>
                <Typography>
                  <Paragraph className="off-white">
                    At The Vault we provide members with an<br />
                    exclusive boutique storage solution<br />
                    program, ensuring best in class care and<br />
                    maintenance.
                  </Paragraph>
                </Typography>
              </div>
              <div>
                {/* <a href="https://shop.vmskw.com/" target="_blank" className="d-flex align-items-center customLink" rel="noreferrer">
                  Visit Website
                  <div className="ms-2 d-flex justify-content-center
                    align-items-center text-center p-0 small_icon rounded-circle"
                  >
                    <Icon className="icon-8" iconRef="#arrow_right" />
                  </div>
                </a> */}

                <Typography.Text className="off-white">Download the app</Typography.Text>
                <div className="d-flex align-items-center flex-wrap">
                  <a href="https://play.google.com/store/apps/details?id=com.vmskw.vault">
                    <img src={playStore} height={40} alt="play store" className="me-3 mt-3" />
                  </a>
                  <a href="https://apps.apple.com/us/app/myvault-logbook/id1638209257">
                    <img src={appStore} height={40} alt="app store" className="mt-3" />
                  </a>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default inject('authStore')(observer(DashboardShop));
