/* eslint-disable react/require-default-props */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Layout,
  Modal,
} from 'antd';
import { inject, observer } from 'mobx-react';
import './Header.less';
import useForm from 'antd/lib/form/hooks/useForm';
import { Icon, Toast } from '..';
import utility from '../../Global/Utility';
import Constant from '../../Global/Constant';
import contactService from '../../Services/contactService';

const { TextArea } = Input;

function Header() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onSubmitForm = () => {
    form.validateFields()
      .then((values) => {
        sendContactReq(values);
      }).catch(() => { });
  };

  const sendContactReq = async (values: any) => {
    await contactService.sendContactUs(values)
      .then(() => {
        setIsModalVisible(false);
        form.resetFields();
        Toast.success('Form submitted successfully');
      })
      .catch((error: any) => Toast.error(error));
  };

  return (
    <Layout.Header id="header" className="px-4 d-flex justify-content-between align-items-center">
      <Layout.Content className="d-flex justify-content-end">
        <Button className="border-0 border-radius primary r-bold h-40" onClick={showModal}>Contact Us</Button>
        <Modal
          title="Complete the form below"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          className="cmn_modal_wrapper"
          bodyStyle={{ background: '#000' }}
          destroyOnClose
          footer={null}
          centered
          closeIcon={(
            <Button className="ms-2 d-flex justify-content-center align-items-center text-center p-0 modal_close" type="link" shape="circle">
              <Icon className="icon-10" iconRef="#close" />
            </Button>
          )}
        >
          <Form
            name="basic"
            layout="vertical"
            autoComplete="off"
            className="mt-4"
            form={form}
            validateTrigger={['onBlur', 'onSubmit', 'onChange']}
            scrollToFirstError
            onSubmitCapture={onSubmitForm}
          >
            <Form.Item
              label="First Name"
              name="first_name"
              required
              normalize={utility.trimSpacesInValues}
              rules={[{
                required: true,
                message: Constant.nameRequired,
              }, {
                type: 'string',
                pattern: /^[A-Za-z][A-Za-z .-]*$/,
                message: Constant.splOrNumberOnlyError,
              }]}
            >
              <Input placeholder="Enter first name" className="inp_style h-40 border-0 shadow-none" maxLength={50} />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="last_name"
              required
              normalize={utility.trimSpacesInValues}
              rules={[{
                required: true,
                message: Constant.lastNameRequired,
              }, {
                type: 'string',
                pattern: /^[A-Za-z][A-Za-z .-]*$/,
                message: Constant.splOrNumberOnlyError,
              }]}
            >
              <Input placeholder="Enter last name" className="inp_style h-40 border-0 shadow-none" maxLength={50} />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              normalize={utility.trimSpacesInValues}
              className="mandatory"
              rules={[{
                required: true,
                message: Constant.emailRequired,
              },
              {
                type: 'email',
                message: Constant.invalidEmail,
              }]}
            >
              <Input maxLength={50} type="email" placeholder="Enter email" className="inp_style h-40 border-0 shadow-none" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="phone"
            >
              <Input maxLength={15} placeholder="Enter phone" type="tel" className="inp_style h-40 border-0 shadow-none" />
            </Form.Item>
            <Form.Item
              label="Your Message"
              name="message"
              required
              normalize={utility.trimSpacesInValues}
              rules={[{
                required: true,
                message: Constant.messageRequired,
              }]}
            >
              <TextArea className="inp_style border-0 shadow-none" maxLength={1000} />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" className="border-0 border-radius primary r-bold h-40">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Layout.Content>
    </Layout.Header>
  );
}

export default inject('UIStore', 'userStore', 'tenantStore')(observer(Header));
