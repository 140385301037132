/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint comma-dangle: ["off"] */

import React from 'react';
import {
  Layout,
  Typography,
} from 'antd';
import './PrivacyPolicy.less';
import { Link } from 'react-router-dom';
import Routes from '../../Global/Routes';

function PrivacyPolicy() {
  return (
    <Layout.Content className="privacyPage_bg">
      <div>
        <Typography.Title level={1} className="text-white text-center">Privacy Policy</Typography.Title>
        <div className="px-3 px-sm-5">
          <div className="text-white">
            <p
              className="s1"
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left'
              }}
            >
              VMS Services
            </p>
            <p
              style={{
                paddingTop: '17pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              This Privacy Policy describes how vms-services.myshopify.com (the “Site” or
              “we”) collects, uses, and discloses your Personal Information when you visit
              or make a purchase from the Site.
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '17pt',
                textAlign: 'left'
              }}
            >
              Contact
            </p>
            <p
              style={{
                paddingTop: '2pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              <a href="mailto:muhannad.nw@gmail.com" className="s3" target="_blank" rel="noreferrer">
                After reviewing this policy, if you have additional questions, want more
                information about our privacy practices, or would like to make a
                complaint, please contact us by e-mail
                muhannad.nw@gmail.com or by mail using the details provided below:
              </a>
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '14pt',
                textAlign: 'left'
              }}
            >
              Kuwait
            </p>
            <p
              className="s2"
              style={{
                paddingTop: '1pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left'
              }}
            >
              Collecting Personal Information
            </p>
            <p
              style={{
                paddingTop: '2pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              When you visit the Site, we collect certain information about your device,
              your interaction with the Site, and information necessary to process your
              purchases. We may also collect additional information if you contact us for
              customer support. In this Privacy Policy, we refer to any information about
              an identifiable individual (including the information below) as “Personal
              Information”. See the list below for more information about what Personal
              Information we collect and why.
            </p>
            <ul id="l1">
              <li data-list-text="●">
                <p
                  className="s4"
                  style={{ paddingLeft: '28pt', textIndent: '-13pt', textAlign: 'left' }}
                >
                  Device information
                </p>
                <ul id="l2">
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '50pt',
                        textIndent: '-13pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      Purpose of collection: to load the Site accurately for you, and to
                      perform analytics on Site usage to optimize our Site.
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingLeft: '50pt',
                        textIndent: '-13pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      Source of collection: Collected automatically when you access our
                      Site using cookies, log files, web beacons, tags, or pixels
                      <i>[ADD OR SUBTRACT ANY OTHER TRACKING TECHNOLOGIES USED]</i>.
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingLeft: '50pt',
                        textIndent: '-13pt',
                        lineHeight: '114%',
                        textAlign: 'justify'
                      }}
                    >
                      Disclosure for a business purpose: shared with our processor Shopify
                      <i>[ADD ANY OTHER VENDORS WITH WHOM YOU SHARE THIS INFORMATION]</i>.
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingLeft: '50pt',
                        textIndent: '-13pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      Personal Information collected: version of web browser, IP address,
                      time zone, cookie information, what sites or products you view,
                      search terms, and how you interact with the Site
                      <i>[ADD OR SUBTRACT ANY OTHER PERSONAL INFORMATION COLLECTED]</i>.
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text="●">
                <p
                  className="s4"
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '14pt',
                    textAlign: 'left'
                  }}
                >
                  Order information
                </p>
                <ul id="l3">
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '50pt',
                        textIndent: '-13pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      Purpose of collection: to provide products or services to you to
                      fulfill our contract, to process your payment information, arrange
                      for shipping, and provide you with invoices and/or order
                      confirmations, communicate with you, screen our orders for potential
                      risk or fraud, and when in line with the preferences you have shared
                      with us, provide you with information or advertising relating to our
                      products or services.
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingLeft: '50pt',
                        textIndent: '-14pt',
                        lineHeight: '14pt',
                        textAlign: 'left'
                      }}
                    >
                      Source of collection: collected from you.
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: '5pt',
                        paddingLeft: '50pt',
                        textIndent: '-13pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      Disclosure for a business purpose: shared with our processor Shopify
                      <i>
                        [ADD ANY OTHER VENDORS WITH WHOM YOU SHARE THIS INFORMATION. FOR
                        EXAMPLE, SALES CHANNELS, PAYMENT GATEWAYS, SHIPPING AND
                        FULFILLMENT APPS]
                      </i>
                      .
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingLeft: '50pt',
                        textIndent: '-13pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      Personal Information collected: name, billing address, shipping
                      address, payment information (including credit card
                    </p>
                    <p
                      style={{
                        paddingLeft: '50pt',
                        textIndent: '0pt',
                        lineHeight: '14pt',
                        textAlign: 'left'
                      }}
                    >
                      numbers <i>[INSERT ANY OTHER PAYMENT TYPES ACCEPTED]</i>),
                    </p>
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '50pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      email address, and phone number.
                    </p>
                  </li>
                </ul>
              </li>
              <li data-list-text="●">
                <p
                  className="s4"
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    textAlign: 'left'
                  }}
                >
                  Customer support information
                </p>
                <ul id="l4">
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '50pt',
                        textIndent: '-14pt',
                        textAlign: 'left'
                      }}
                    >
                      Purpose of collection:
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '50pt',
                        textIndent: '-14pt',
                        textAlign: 'left'
                      }}
                    >
                      Source of collection:
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '50pt',
                        textIndent: '-14pt',
                        textAlign: 'left'
                      }}
                    >
                      Disclosure for a business purpose:
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '50pt',
                        textIndent: '-13pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      Personal Information collected:
                      <i>
                        [INSERT ANY OTHER INFORMATION YOU COLLECT: OFFLINE DATA, PURCHASED
                        MARKETING DATA/LISTS]
                      </i>
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingLeft: '50pt',
                        textIndent: '-14pt',
                        lineHeight: '14pt',
                        textAlign: 'left'
                      }}
                    >
                      Purpose of collection: to provide customer support.
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '50pt',
                        textIndent: '-14pt',
                        textAlign: 'left'
                      }}
                    >
                      Source of collection: collected from you
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '50pt',
                        textIndent: '-13pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      Disclosure for a business purpose:
                      <i>[ADD ANY VENDORS USED TO PROVIDE CUSTOMER SUPPORT]</i>
                    </p>
                  </li>
                  <li data-list-text="○">
                    <p
                      style={{
                        paddingLeft: '50pt',
                        textIndent: '-13pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      Personal Information collected:
                      <i>
                        [ADD ANY MODIFICATIONS TO THE INFORMATION LISTED ABOVE OR
                        ADDITIONAL INFORMATION AS NEED.]
                      </i>
                    </p>
                    <p
                      className="s5"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '14pt',
                        textAlign: 'left'
                      }}
                    >
                      [INSERT FOLLOWING SECTION IF AGE RESTRICTION IS REQUIRED]
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Minors
                    </p>
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      The Site is not intended for individuals under the age of
                      <i>[INSERT AGE]</i>. We do not intentionally collect Personal
                      Information from children. If you are the parent or guardian and
                      believe your child has provided us with Personal Information, please
                      contact us at the address above to request deletion.
                    </p>
                    <p
                      className="s2"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Sharing Personal Information
                    </p>
                    <p
                      style={{
                        paddingTop: '2pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '114%',
                        textAlign: 'left'
                      }}
                    >
                      We share your Personal Information with service providers to help us
                      provide our services and fulfill our contracts with you, as
                      described above. For example:
                    </p>
                    <p style={{ textIndent: '0pt', textAlign: 'left' }} />
                  </li>
                </ul>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  We use Shopify to power our online store. You can read more about how
                  Shopify uses your Personal Information here:
                  <a
                    href="http://www.shopify.com/legal/privacy"
                    className="s8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.shopify.com/legal/privacy.
                  </a>
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  We may share your Personal Information to comply with applicable laws
                  and regulations, to respond to a subpoena, search warrant or other
                  lawful request for information we receive, or to otherwise protect our
                  rights.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '14pt',
                    textAlign: 'left'
                  }}
                >
                  [INSERT INFORMATION ABOUT OTHER SERVICE PROVIDERS]
                </p>
                <p
                  className="s5"
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  [INCLUDE FOLLOWING SECTION IF USING REMARKETING OR TARGETED ADVERTISING]
                </p>
                <p
                  className="s2"
                  style={{
                    paddingTop: '4pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    textAlign: 'justify'
                  }}
                >
                  Behavioural Advertising
                </p>
                <p
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'justify'
                  }}
                >
                  As described above, we use your Personal Information to provide you with
                  targeted advertisements or marketing communications we believe may be of
                  interest to you. For example:
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }} />
                <p style={{ textIndent: '0pt', textAlign: 'left' }} />
              </li>
              <li data-list-text="●">
                <p
                  className="s6"
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  <span className="s5">[INSERT IF APPLICABLE] </span>
                  <span className="p">
                    We use Google Analytics to help us understand how our customers use
                    the Site. You can read more about how Google uses your Personal
                    Information here:
                  </span>

                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="s8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://
                  </a>
                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.
                  </a>
                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="s8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    g
                  </a>
                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    oo
                  </a>
                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="s8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    g
                  </a>
                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    le.com/intl/en
                  </a>
                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="s8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    /p
                  </a>
                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    olicies
                  </a>
                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="s8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    /p
                  </a>
                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="a"
                    target="_blank"
                    rel="noreferrer"
                  >
                    rivac
                  </a>
                  <a
                    href="http://www.google.com/intl/en/policies/privacy/"
                    className="s8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    y/
                  </a>
                  <span className="p">
                    . You can also opt-out of Google Analytics here:
                  </span>
                  https://tools.google.com/dlpage/gaoptout.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  [INSERT IF YOU USE A THIRD PARTY MARKETING APP THAT COLLECTS INFORMATION
                  ABOUT BUYER ACTIVITY ON YOUR SITE]
                  <span className="p">
                    We share information about your use of the Site, your purchases, and
                    your interaction with our ads on other websites with our advertising
                    partners. We collect and share some of this
                  </span>
                </p>
                <p
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  information directly with our advertising partners, and in some cases
                  through the use of cookies or other similar technologies (which you may
                  consent to, depending on your location).
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  [INSERT IF USING SHOPIFY AUDIENCES]
                  <span className="p">
                    We use Shopify Audiences to help us show ads on other websites with
                    our advertising partners to buyers who made purchases with other
                    Shopify merchants and who may also be interested in what we have to
                    offer. We also share information about your use of the Site, your
                    purchases, and the email address associated with your purchases with
                    Shopify Audiences, through which other Shopify merchants may make
                    offers you may be interested in.
                  </span>
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '14pt',
                    textAlign: 'left'
                  }}
                >
                  [INSERT OTHER ADVERTISING SERVICES USED]
                </p>
                <p
                  className="s6"
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  <span className="p">
                    For more information about how targeted advertising works, you can
                    visit the Network Advertising Initiative’s (“NAI”) educational page at
                  </span>
                  <a
                    href="http://www.networkadvertising.org/understanding-online-advertising/how-does-"
                    className="s8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.networkadvertising.org/g-online-advertising/how-does-it-work
                  </a>
                  <span className="p">.</span>
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '14pt',
                    textAlign: 'left'
                  }}
                >
                  You can opt out of targeted advertising by:
                </p>
                <p
                  className="s5"
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  [INCLUDE OPT-OUT LINKS FROM WHICHEVER SERVICES BEING USED. COMMON LINKS
                  INCLUDE:
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{ paddingLeft: '28pt', textIndent: '-13pt', textAlign: 'left' }}
                >
                  FACEBOOK - <span className="s9">https</span>
                  <span style={{ color: '#E4AF0A' }}>:/</span>
                  <a
                    href="http://www.facebook.com/settings/?tab=ads"
                    className="s11"
                    target="_blank"
                    rel="noreferrer"
                  >
                    /www.facebook.com/settin
                  </a>
                  <a
                    href="http://www.facebook.com/settings/?tab=ads"
                    className="s12"
                    target="_blank"
                    rel="noreferrer"
                  >
                    g
                  </a>
                  <a
                    href="http://www.facebook.com/settings/?tab=ads"
                    className="s11"
                    target="_blank"
                    rel="noreferrer"
                  >
                    s/?tab=ads
                  </a>
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }} />
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    textAlign: 'left'
                  }}
                >
                  GOOGLE - <span className="s9">https</span>
                  <span style={{ color: '#E4AF0A' }}>:/</span>
                  <a
                    href="http://www.google.com/settings/ads/anonymous"
                    className="s11"
                    target="_blank"
                    rel="noreferrer"
                  >
                    /www.
                  </a>
                  <a
                    href="http://www.google.com/settings/ads/anonymous"
                    className="s12"
                    target="_blank"
                    rel="noreferrer"
                  >
                    g
                  </a>
                  <a
                    href="http://www.google.com/settings/ads/anonymous"
                    className="s11"
                    target="_blank"
                    rel="noreferrer"
                  >
                    oo
                  </a>
                  <a
                    href="http://www.google.com/settings/ads/anonymous"
                    className="s12"
                    target="_blank"
                    rel="noreferrer"
                  >
                    g
                  </a>
                  <a
                    href="http://www.google.com/settings/ads/anonymous"
                    className="s11"
                    target="_blank"
                    rel="noreferrer"
                  >
                    le.com/settin
                  </a>
                  <a
                    href="http://www.google.com/settings/ads/anonymous"
                    className="s12"
                    target="_blank"
                    rel="noreferrer"
                  >
                    g
                  </a>
                  <a
                    href="http://www.google.com/settings/ads/anonymous"
                    className="s11"
                    target="_blank"
                    rel="noreferrer"
                  >
                    s/ads/anon
                  </a>
                  <a
                    href="http://www.google.com/settings/ads/anonymous"
                    className="s12"
                    target="_blank"
                    rel="noreferrer"
                  >
                    y
                  </a>
                  <a
                    href="http://www.google.com/settings/ads/anonymous"
                    className="s11"
                    target="_blank"
                    rel="noreferrer"
                  >
                    mous
                  </a>
                </p>
                <p style={{ textIndent: '0pt', textAlign: 'left' }} />
                <p style={{ textIndent: '0pt', textAlign: 'left' }} />
              </li>
              <li data-list-text="●">
                <p
                  className="s9"
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  <span className="s5">BING - </span>https<span className="s10">:/</span>
                  /advertise.bin<span className="s10">g</span>
                  ads.microsoft.com/en-us/resources<span className="s10">/ p</span>olicies
                  <span className="s10">/p</span>ersonalized-ads
                  <span className="s5">]</span>
                </p>
                <p
                  className="s6"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  <span className="p">
                    Additionally, you can opt out of some of these services by visiting
                    the Digital Advertising Alliance’s opt-out portal at:
                  </span>
                  https://optout.aboutads.info/.
                </p>
                <p
                  className="s2"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '17pt',
                    textAlign: 'left'
                  }}
                >
                  Using Personal Information
                </p>
                <p
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  We use your personal Information to provide our services to you, which
                  includes: offering products for sale, processing payments, shipping and
                </p>
                <p
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  fulfillment of your order, and keeping you up to date on new products,
                  services, and offers.
                </p>
                <p
                  className="s5"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left',
                  }}
                >
                  [INCLUDE THE FOLLOWING SECTION IF YOUR STORE IS LOCATED IN OR IF YOU
                  HAVE CUSTOMERS IN EUROPE]
                </p>
                <p
                  className="s2"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '17pt',
                    textAlign: 'left',
                  }}
                >
                  Lawful basis
                </p>
                <p
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left',
                  }}
                >
                  Pursuant to the General Data Protection Regulation (“GDPR”), if you are
                  a resident of the European Economic Area (“EEA”), we process your
                  personal information under the following lawful bases:
                </p>
                <p
                  className="s5"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '14pt',
                    textAlign: 'left'
                  }}
                >
                  [INCLUDE ALL THAT APPLY TO YOUR BUSINESS]
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    textAlign: 'left'
                  }}
                >
                  Your consent;
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    textAlign: 'left'
                  }}
                >
                  The performance of the contract between you and the Site;
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    textAlign: 'left'
                  }}
                >
                  Compliance with our legal obligations;
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    textAlign: 'left'
                  }}
                >
                  To protect your vital interests;
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    textAlign: 'left'
                  }}
                >
                  To perform a task carried out in the public interest;
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  For our legitimate interests, which do not override your fundamental
                  rights and freedoms.
                </p>
                <p
                  className="s2"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '17pt',
                    textAlign: 'left'
                  }}
                >
                  Retention
                </p>
                <p
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  When you place an order through the Site, we will retain your Personal
                  Information for our records unless and until you ask us to erase this
                  information. For more information on your right of erasure, please see
                  the ‘Your rights’ section below.
                </p>
                <p
                  className="s2"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '17pt',
                    textAlign: 'left'
                  }}
                >
                  Automatic decision-making
                </p>
                <p
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  If you are a resident of the EEA, you have the right to object to
                  processing based solely on automated decision-making (which includes
                  profiling), when that decision-making has a legal effect on you or
                  otherwise significantly affects you.
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  We <i>[DO/DO NOT] </i>engage in fully automated decision-making that has
                  a legal or otherwise significant effect using customer data.
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  Our processor Shopify uses limited automated decision-making to prevent
                  fraud that does not have a legal or otherwise significant effect on you.
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '14pt',
                    textAlign: 'left'
                  }}
                >
                  Services that include elements of automated decision-making include:
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingTop: '1pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  Temporary blacklist of IP addresses associated with repeated failed
                  transactions. This blacklist persists for a small number of hours.
                </p>
              </li>
              <li data-list-text="●">
                <p
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  Temporary blacklist of credit cards associated with blacklisted IP
                  addresses. This blacklist persists for a small number of days.
                </p>
                <p
                  className="s2"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '17pt',
                    textAlign: 'left'
                  }}
                >
                  Selling Personal Information
                </p>
                <p
                  className="s5"
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  [INCLUDE THIS SECTION IF YOUR BUSINESS IS SUBJECT TO THE CALIFORNIA
                  CONSUMER PRIVACY ACT AND SELLS PERSONAL INFORMATION AS DEFINED BY THE
                  CALIFORNIA CONSUMER PRIVACY ACT]
                </p>
                <p
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  Our Site sells Personal Information, as defined by the California
                  Consumer Privacy Act of 2018 (“CCPA”).
                </p>
                <p
                  className="s5"
                  style={{
                    paddingLeft: '5pt',
                    textIndent: '0pt',
                    lineHeight: '14pt',
                    textAlign: 'left'
                  }}
                >
                  [Insert:
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{
                    paddingTop: '5pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    textAlign: 'left'
                  }}
                >
                  categories of information sold;
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  IF USING SHOPIFY AUDIENCES: information about your use of the Site, your
                  purchases, and the email address associated with your purchase
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '14pt',
                    textAlign: 'left'
                  }}
                >
                  instructions on how to opt-out of sale;
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{
                    paddingTop: '2pt',
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  whether your business sells information of minors (under 16) and whether
                  you obtain affirmative authorization;
                </p>
              </li>
              <li data-list-text="●">
                <p
                  className="s5"
                  style={{
                    paddingLeft: '28pt',
                    textIndent: '-13pt',
                    lineHeight: '114%',
                    textAlign: 'left'
                  }}
                >
                  if you provide a financial incentive to sell information, provide
                  information about what that incentive is.]
                </p>
              </li>
            </ul>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}>
              <br />
            </p>
            <p
              className="s2"
              style={{
                paddingTop: '5pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left'
              }}
            >
              Your rights
            </p>
            <p
              className="s5"
              style={{
                paddingTop: '2pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              [INCLUDE FOLLOWING SECTION IF YOUR STORE IS LOCATED IN OR IF YOU HAVE
              CUSTOMERS IN EUROPE]
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '17pt',
                textAlign: 'left'
              }}
            >
              GDPR
            </p>
            <p
              style={{
                paddingTop: '2pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              If you are a resident of the EEA, you have the right to access the Personal
              Information we hold about you, to port it to a new service, and to ask that
              your Personal Information be corrected, updated, or erased. If you would
              like to exercise these rights, please contact us through the contact
              information above.
              <i>
                [OR INSERT ALTERNATIVE INSTRUCTIONS FOR SENDING ACCESS, ERASURE,
                CORRECTION, AND PORTABILITY REQUESTS]
              </i>
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              Your Personal Information will be initially processed in Ireland and then
              will be transferred outside of Europe for storage and further processing,
              including to Canada and the United States. For more information on how data
              transfers comply with the GDPR, see Shopify’s GDPR
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <p
              className="s6"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              <span className="p">Whitepaper: </span>https://help.shopify.com/en/manual/your-account/privacy/GDPR<span className="p">.</span>
            </p>
            <p
              className="s5"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              [INCLUDE FOLLOWING SECTION IF YOUR BUSINESS IS SUBJECT TO THE CALIFORNIA
              CONSUMER PRIVACY ACT]
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '17pt',
                textAlign: 'left'
              }}
            >
              CCPA
            </p>
            <p
              style={{
                paddingTop: '2pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              If you are a resident of California, you have the right to access the
              Personal Information we hold about you (also known as the ‘Right to Know’),
              to port it to a new service, and to ask that your Personal Information be
              corrected, updated, or erased. If you would like to exercise these rights,
              please contact us through the contact information
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              above.
              <i>
                [OR INSERT ALTERNATIVE INSTRUCTIONS FOR SENDING ACCESS, ERASURE,
                CORRECTION, AND PORTABILITY REQUESTS]
              </i>
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '112%',
                textAlign: 'left'
              }}
            >
              If you would like to designate an authorized agent to submit these requests
              on your behalf, please contact us at the address above.
              <span className="s13">Cookies</span>
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              A cookie is a small amount of information that’s downloaded to your computer
              or device when you visit our Site. We use a number of different cookies,
              including functional, performance, advertising, and social media
            </p>
            <p
              style={{
                paddingTop: '5pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              or content cookies. Cookies make your browsing experience better by allowing
              the website to remember your actions and preferences (such as login and
              region selection). This means you don’t have to re-enter this information
              each time you return to the site or browse from one page to another. Cookies
              also provide information on how people use the website, for instance whether
              it’s their first time visiting or if they are a frequent visitor.
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              We use the following cookies to optimize your experience on our Site and to
              provide our services.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <table style={{ borderCollapse: 'collapse' }} cellSpacing={0}>
              <tbody>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '54pt',
                        paddingRight: '53pt',
                        textIndent: '0pt',
                        textAlign: 'center'
                      }}
                    >
                      Name
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '45pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Function
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '46pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Duration
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _ab
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '6pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with access to admin.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '52pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _secure_session_id
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '17pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with navigation through a storefront.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      24h
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_country
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      session
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '52pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_m
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used for managing customer privacy settings.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '52pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_tm
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used for managing customer privacy settings.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      30min
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '52pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_tw
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used for managing customer privacy settings.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2w
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '52pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _storefront_u
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used to facilitate updating customer account information.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1min
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _tracking_consent
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Tracking preferences.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      c
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      cart
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with shopping cart.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2w
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      cart_currency
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with shopping cart.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2w
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      cart_sig
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2w
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <p
              className="s9"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '112%',
                textAlign: 'left'
              }}
            >
              <span className="s5">
                [Be sure to check this list against Shopify’s current list of cookies on
                the merchant storefront:
              </span>
              https<span className="s10">:/</span>
              <a
                href="http://www.shopify.com/legal/cookies"
                className="s11"
                target="_blank"
                rel="noreferrer"
              >
                /www.shopif
              </a>
              <a
                href="http://www.shopify.com/legal/cookies"
                className="s12"
                target="_blank"
                rel="noreferrer"
              >
                y.com/leg
              </a>
              al/cookies<span className="s10"> </span>
              <span className="s5">] </span>
              <span className="s2">
                Cookies Necessary for the Functioning of the Store
              </span>
            </p>
            <table
              style={{
                borderCollapse: 'collapse', marginLeft: '6.03721pt'
              }}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      cart_ts
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2w
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      cart_ver
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with shopping cart.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2w
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      checkout
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      4w
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      checkout_token
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      dynamic_checkout_sh own_on_cart
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      30min
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      hide_shopify_pay_for_ checkout
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      session
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '52pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      keep_alive
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '6pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with buyer localization.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2w
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      master_device_id
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '20pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with merchant login.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      previous_step
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      remember_me
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      secure_customer_sig
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '20pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with customer login.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      20y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      shopify_pay
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      shopify_pay_redirect
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      30 minutes, 3w or 1y depending on value
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      storefront_digest
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '20pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with customer login.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '6pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      tracked_start_checko ut
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      checkout_one_experi ment
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '26pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Used in connection with checkout.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      session
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style={{
                paddingTop: '1pt',
                paddingBottom: '1pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left'
              }}
            >
              Reporting and Analytics
            </p>
            <table
              style={{
                borderCollapse: 'collapse', marginLeft: '6.03721pt'
              }}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '54pt',
                        paddingRight: '53pt',
                        textIndent: '0pt',
                        textAlign: 'center'
                      }}
                    >
                      Name
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '45pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Function
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '46pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Duration
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _landing_page
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Track landing pages.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2w
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _orig_referrer
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Track landing pages.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      2w
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _s
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Shopify analytics.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      30min
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_d
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Shopify analytics.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      session
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_s
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Shopify analytics.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      30min
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              style={{
                borderCollapse: 'collapse', marginLeft: '6.03721pt'
              }}
              cellSpacing={0}
            >
              <tbody>
                <tr style={{ height: '52pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_sa_p
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Shopify analytics relating to marketing &amp; referrals.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      30min
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '52pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_sa_t
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Shopify analytics relating to marketing &amp; referrals.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      30min
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_y
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Shopify analytics.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _y
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Shopify analytics.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      1y
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '19pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_evids
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      Shopify analytics.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      session
                    </p>
                  </td>
                </tr>
                <tr style={{ height: '36pt' }}>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      _shopify_ga
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >
                    <p
                      className="s14"
                      style={{
                        paddingLeft: '5pt',
                        paddingRight: '23pt',
                        textIndent: '0pt',
                        lineHeight: '17pt',
                        textAlign: 'left'
                      }}
                    >
                      Shopify and Google Analytics.
                    </p>
                  </td>
                  <td
                    style={{
                      width: '147pt',
                      borderTopStyle: 'solid',
                      borderTopWidth: '1pt',
                      borderTopColor: '#AAAAAA',
                      borderLeftStyle: 'solid',
                      borderLeftWidth: '1pt',
                      borderLeftColor: '#AAAAAA',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1pt',
                      borderBottomColor: '#AAAAAA',
                      borderRightStyle: 'solid',
                      borderRightWidth: '1pt',
                      borderRightColor: '#AAAAAA'
                    }}
                  >=
                    <p
                      className="s14"
                      style={{
                        paddingTop: '1pt',
                        paddingLeft: '5pt',
                        textIndent: '0pt',
                        textAlign: 'left'
                      }}
                    >
                      session
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              className="s5"
              style={{
                paddingTop: '1pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              [INSERT OTHER COOKIES OR TRACKING TECHNOLOGIES THAT YOU USE]
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              The length of time that a cookie remains on your computer or mobile device
              depends on whether it is a “persistent” or “session” cookie.
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              Session cookies last until you stop browsing and persistent cookies last
              until they expire or are deleted. Most of the cookies we use are persistent
              and will expire between 30 minutes and two years from the date they are
              downloaded to your device.
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              You can control and manage cookies in various ways. Please keep in mind that
              removing or blocking cookies can negatively impact your user experience and
              parts of our website may no longer be fully accessible.
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              Most browsers automatically accept cookies, but you can choose whether or
              not to accept cookies through your browser controls, often found in your
              browser’s “Tools” or “Preferences” menu. For more information on how to
              modify your browser settings or how to block, manage or filter cookies can
              be found in your browser’s help file or through such sites
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '14pt',
                textAlign: 'left'
              }}
            >
              <a href="http://www.allaboutcookies.org/" className="s3" target="_blank" rel="noreferrer">
                as:
              </a>
              <a href="http://www.allaboutcookies.org/" className="a" target="_blank" rel="noreferrer">
                www.allaboutcookies.or
              </a>
              <a href="http://www.allaboutcookies.org/" className="s8" target="_blank" rel="noreferrer">
                g
              </a>
              <a href="http://www.allaboutcookies.org/" className="s3" target="_blank" rel="noreferrer">
                .
              </a>
            </p>
            <p
              style={{
                paddingTop: '1pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              Additionally, please note that blocking cookies may not completely prevent
              how we share information with third parties such as our advertising
              partners. To exercise your rights or opt-out of certain uses of your
              information by these parties, please follow the instructions in the
              “Behavioural Advertising” section above.
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '17pt',
                textAlign: 'left'
              }}
            >
              Do Not Track
            </p>
            <p
              style={{
                paddingTop: '2pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              Please note that because there is no consistent industry understanding of
              how to respond to “Do Not Track” signals, we do not alter our data
              collection and usage practices when we detect such a signal from your
              browser.
            </p>
            <p
              className="s2"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '17pt',
                textAlign: 'left'
              }}
            >
              Changes
            </p>
            <p
              style={{
                paddingTop: '2pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              We may update this Privacy Policy from time to time in order to reflect, for
              example, changes to our practices or for other operational, legal, or
              regulatory reasons.
            </p>
            <p
              className="s2"
              style={{
                paddingTop: '4pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                textAlign: 'left'
              }}
            >
              Complaints
            </p>
            <p
              style={{
                paddingTop: '2pt',
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              As noted above, if you would like to make a complaint, please contact us by
              e-mail or by mail using the details provided under “Contact” above.
            </p>
            <p style={{ textIndent: '0pt', textAlign: 'left' }} />
            <p
              className="s9"
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '114%',
                textAlign: 'left'
              }}
            >
              <span className="p">
                If you are not satisfied with our response to your complaint, you have the
                right to lodge your complaint with the relevant data protection authority.
                You can contact your local data protection authority, or our supervisory
                authority here:
              </span>
              <span className="s5">
                [Add contact information or website for the data protection authority in
                your jurisdiction. For example:
              </span>
              https://ico.or<span className="s10">g</span>.uk/make-a-complaint
              <span className="s10">/</span>
              <span className="s5">]</span>
            </p>
            <p
              style={{
                paddingLeft: '5pt',
                textIndent: '0pt',
                lineHeight: '14pt',
                textAlign: 'left'
              }}
            >
              Last updated: <i>[Date]</i>
            </p>
          </div>

        </div>
        <Link to={Routes.home} className="d-table m-auto">
          <div className="border-0 border-radius primary r-bold h-40 bg-white d-flex justify-content-center align-items-center px-5">Home</div>
        </Link>
      </div>
    </Layout.Content>
  );
}

export default PrivacyPolicy;
