/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import api from './api';

class TenantService {
    getTenantAccess = async () => {
      try {
        const response = await api.get('/master/tenants/access');
        return Promise.resolve(response);
      } catch (error: any) {
        return Promise.reject(error);
      }
    }
}

export default new TenantService();
