/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import queryString from 'query-string';
import Constant from '../Global/Constant';
import { ActivityQueryParams } from '../Global/interface';
import api from './api';

class UserService {
  getUserById = async (id?: String) => {
    try {
      const response = await api.get(`/users/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  createUser = async (user : any) => {
    try {
      const response = await api.post('/users', user);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  updateUser = async (id: string, user : any) => {
    try {
      const response = await api.put(`/users/${id}`, user);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  deleteUser = async (id: string) => {
    try {
      const response = await api.delete(`/users/${id}`);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  getUsers = (keyword?: string|null,
    page?: number, limit?: number,
    order?: string, orderBy?: number, include_logged_user?: boolean) => {
    const query = queryString.stringify({
      keyword, limit, page, order, orderBy, include_logged_user,
    }, { skipNull: true });
    const url = `/users/list?${query}`;
    return api.get(url);
  }

  resetPassword = async (password:any) => {
    try {
      const response = await api.put('/users/reset-password', password);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  getUserActivity = async (queryParams:ActivityQueryParams) => {
    try {
      const query = queryString.stringify(queryParams, { skipNull: true });
      const url = `/customer-contracts/activities?${query}`;
      const response = await api.get(url);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  resendInvitation = async (id: string) => {
    try {
      const response = await api.post(`/users/resend-invitation/${id}`, null);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new UserService();
