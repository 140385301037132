/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
// import PrivateRoute from './PrivateRoute';
import AccessRoute from './AccessRoute';
import Routes from '../Global/Routes';
import Header from '../Components/Header';
import {
  DashboardShop, PrivacyPolicy, TermsAndConditions, Cloud, VmsVault,
} from '../Pages';
import PublicRoute from './PublicRoute';
import { Footer } from '../Components';

const Router = () => (
  <>
    <Header />
    <Layout>
      <Layout.Content className="body_layout">
        <Switch>
          {/* <Route path={Routes.notFound} component={NotFound} exact /> */}
          <PublicRoute path={Routes.home} component={DashboardShop} exact />
          <PublicRoute path={Routes.privacy_policy} component={PrivacyPolicy} exact />
          <PublicRoute path={Routes.terms_and_conditions} component={TermsAndConditions} exact />
          <PublicRoute path={Routes.cloud} component={Cloud} exact />
          <PublicRoute path={Routes.vault} component={VmsVault} exact />
        </Switch>
      </Layout.Content>
    </Layout>
    <Footer />
  </>
);

export default Router;
