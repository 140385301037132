/* eslint-disable import/no-unresolved */
import { Button, notification } from 'antd';
import React from 'react';
import { Icon } from '..';

const closeButton = (
  <Button className="login_close h-100 d-flex justify-content-center align-items-center text-center p-0" type="link" shape="circle">
    <Icon className="icon-10" iconRef="#close" />
  </Button>
);

class Toast {
  error = (message:string) => notification.error({
    placement: 'bottomLeft',
    description: message,
    duration: 5,
    message: '',
    key: 'error',
    closeIcon:
    closeButton,
  })

  success= (message:string) => notification.success({
    placement: 'bottomLeft',
    description: message,
    duration: 5,
    message: '',
    key: 'success',
    closeIcon:
    closeButton,
  })

  warning= (message:string) => notification.warning({
    placement: 'bottomLeft',
    description: message,
    duration: 5,
    message: '',
    key: 'warning',
    closeIcon:
    closeButton,
  })

  info= (message:string) => notification.info({
    placement: 'bottomLeft',
    description: message,
    duration: 5,
    message: '',
    key: 'info',
    closeIcon:
  closeButton,
  })
}

export default new Toast();
