/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
  // Button,
  Col,
  Layout,
  Row,
  // Typography,
} from 'antd';
import './DashboardShop.less';
// import Icon from '../../Components/Icon';
// import vmsLogo from '../../Assets/Images/vms-logo.png';
import vmsLogoFinal from '../../Assets/Images/vms-logo-final.png';
import vmsCloudLogo from '../../Assets/Images/VMS_Cloud_logo.png';
import vmsshopLogo from '../../Assets/Images/VMS_shop_logo.png';
import { Icon } from '../../Components';
// import voultLogo from '../../Assets/Images/voult.png';
// import playStore from '../../Assets/Images/playStore.png';
// import appStore from '../../Assets/Images/appStore.png';

// const { Title, Paragraph } = Typography;

function DashboardShop() {
  return (
    <Layout.Content>
      <Row>
        <Col lg={8} md={24} sm={24} xs={24} className="order-1 order-lg-3">
          <Card className="customCard customCardbg px-3 px-sm-5" bordered={false} bodyStyle={{ height: '100%' }}>
            <div className="d-flex flex-column justify-content-evenly align-items-center text-center h-100">
              <img src={vmsshopLogo} width={150} alt="logo" />
              <a href="https://shop.vmskw.com/" target="_blank" className="d-flex align-items-center customLink" rel="noreferrer">
                Visit Website
                <div className="ms-2 d-flex justify-content-center
                    align-items-center text-center p-0 small_icon rounded-circle"
                >
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </a>
            </div>
          </Card>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} className="order-3 order-lg-1">
          <Card className="customCard customCardbg3 px-3 px-sm-5" bordered={false} bodyStyle={{ height: '100%' }}>
            <div className="d-flex flex-column justify-content-evenly align-items-center text-center h-100">
              <img src={vmsCloudLogo} width={150} alt="logo" className="" />
              <a href="cloud" className="d-flex align-items-center customLink">
                Find Out More
                <div className="ms-2 d-flex justify-content-center
                    align-items-center text-center p-0 small_icon rounded-circle"
                >
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </a>
            </div>
          </Card>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} className="order-2 order-lg-2">
          <Card className="customCard customCardbg2 px-3 px-sm-5" bordered={false} bodyStyle={{ height: '100%' }}>
            <div className="d-flex flex-column justify-content-evenly align-items-center text-center h-100">
              <img src={vmsLogoFinal} width={150} alt="logo" className="mt-4 mb-4" />
              <a href="vault" className="d-flex align-items-center customLink">
                Find Out More
                <div className="ms-2 d-flex justify-content-center
                    align-items-center text-center p-0 small_icon rounded-circle"
                >
                  <Icon className="icon-8" iconRef="#arrow_right" />
                </div>
              </a>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout.Content>
  );
}

export default inject('authStore')(observer(DashboardShop));
