/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import { observable, makeObservable, runInAction } from 'mobx';

class MasterStore {
	sideBarOpen: boolean = false;

	collapsed: boolean = false;

	constructor() {
	  makeObservable(this, {
	    sideBarOpen: observable,
	    collapsed: observable,
	  });
	}

	openSideBar() {
	  runInAction(() => {
	  this.sideBarOpen = true;
	  });
	}

	closeSideBar() {
	  runInAction(() => {
	  this.sideBarOpen = false;
	  });
	}

	collapseSideBar() {
	  runInAction(() => {
	    this.collapsed = true;
	  });
	  }

	  expandSideBar() {
	  runInAction(() => {
	    this.collapsed = false;
	  });
	  }
}

export default new MasterStore();
