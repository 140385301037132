/* eslint-disable react/require-default-props */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import {
  Layout,
} from 'antd';
import { inject, observer } from 'mobx-react';
import './Footer.less';
import { Link } from 'react-router-dom';
import Routes from '../../Global/Routes';

function Footer() {
  return (
    <Layout.Footer id="footer" className="px-4">
      <Layout.Content className="off-white text-center text-md-start">
        The VMS • Copyright © 2023 •
        <div className="clearfix d-md-none" />
        <Link to={Routes.terms_and_conditions} className="off-white ftr_link"> Terms And Conditions </Link> •
        <Link to={Routes.privacy_policy} className="off-white ftr_link"> Privacy Policy</Link>
      </Layout.Content>
    </Layout.Footer>
  );
}

export default inject('UIStore', 'userStore', 'tenantStore')(observer(Footer));
