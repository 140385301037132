/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import {
  Layout,
  Space,
  Typography,
} from 'antd';
import './Cloud.less';
import { Link } from 'react-router-dom';
import Routes from '../../Global/Routes';
import vmsCloudLogo from '../../Assets/Images/VMS_Cloud_logo.png';

function Cloud() {
  return (
    <Layout.Content className="cloudPage_bg">
      <div className="container text-center d-flex flex-column align-items-center justify-content-around cloud_min_ht">
        <img src={vmsCloudLogo} width={150} alt="logo" className="mb-4" />
        <div className="p_section px-3 px-sm-5">
          <Typography.Paragraph className="off-white fs-16">
            VMS Cloud Services are business operationing and point of sale cloud based system
            for running vehicle related businesses.
          </Typography.Paragraph>
          <Typography.Paragraph className="off-white fs-16">
            <b className="text-white">Cloud Frame Basic</b>: parking business contract manger, invoice manager
          </Typography.Paragraph>
          <Typography.Paragraph className="off-white fs-16">
            <b className="text-white">Vehicle Managment App</b>: mobile application for parking business to provide for their clients under it&apos;s own label
          </Typography.Paragraph>
          <Typography.Paragraph className="off-white fs-16">
            <b className="text-white">E Commerce</b>: website for parking business to create their own platform displaying ecommerce under it&apos;s own label
          </Typography.Paragraph>
        </div>
        <div>
          <Typography.Title level={2} className="text-white">Ready to get started or just have questions? </Typography.Title>
          <Typography.Paragraph className="off-white fs-16">
            Let us know what you would like to discuss and we’ll be in touch shortly.
          </Typography.Paragraph>
        </div>
        <div className="d-flex align-items-center flex-wrap mb-3">
          <Space>
            <Link to={Routes.home}>
              <div className="border-0 border-radius primary r-bold h-40 bg-white d-flex justify-content-center align-items-center px-5">
                {/* <Icon className="icon-14" iconRef="#facilities" />&nbsp; */}
                Home
              </div>
            </Link>
            <Link to={Routes.home} className="d-none">
              <div className="border-0 border-radius primary r-bold h-40 bg-white d-flex justify-content-center align-items-center px-5">Sign Up for Business</div>
            </Link>
          </Space>
        </div>
      </div>
    </Layout.Content>
  );
}

export default Cloud;
