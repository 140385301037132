const Routes = {
  home: '/',
  dashboard_shop: '/dashboard_shop',
  privacy_policy: '/privacy_policy',
  terms_and_conditions: '/terms_and_conditions',
  cloud: '/cloud',
  vault: '/vault',

};

export default Routes;
