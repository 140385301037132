/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import authStore from '../Store/authStore';
import constant from '../Global/Constant';
import Utility from '../Global/Utility';
import tenantConfig from '../config.json';

const baseUrl = process.env.REACT_APP_BASE_URL;

class API {
  constructor() {
    axios.defaults.baseURL = baseUrl;
    // Use this to inject anything with all the request
    axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token');
        const item = { ...config };
        item.headers.Tenant = tenantConfig?.TENANT_ID;
        if (token) {
          item.headers.Authorization = `Bearer ${token}`;
          return item;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      (responseInter) => responseInter,
      (error) => new Promise((resolve, reject) => {
        const originalReq = error?.config;
        const token = localStorage.getItem(constant.token);
        const refreshToken = localStorage.getItem(constant.refreshToken);

        if (
          refreshToken
          && error?.response?.status === 401
          && !originalReq?._retry
        ) {
          const refreshExpireTime = Utility.getRefreshTokenTime();
          if (refreshExpireTime && refreshExpireTime * 1000 < new Date().getTime()) {
            authStore.signOut();
          } else {
            originalReq._retry = true;
            const res = fetch(`${axios.defaults.baseURL}/auth/refresh-token`, {
              method: 'POST',
              mode: 'cors',
              cache: 'no-cache',
              credentials: 'same-origin',
              headers: new Headers({
                'Content-Type': 'application/json',
                Tenant: tenantConfig?.TENANT_ID,
              }),
              redirect: 'follow',
              referrer: 'no-referrer',
              body: JSON.stringify({
                refreshToken,
              }),
            })
              .then((response) => {
                if (response?.status === 200) {
                  return response.json();
                }
                authStore.signOut();
              })
              .then((response) => {
                if (response) {
                  localStorage.setItem(constant.token, response?.tokens?.accessToken);
                  localStorage.setItem(constant.refreshToken, response?.tokens?.refreshToken);
                  originalReq.headers.Authorization = `Bearer ${token}`;
                  return axios(originalReq);
                }
              });
            resolve(res);
          }
        }
        reject(error);
      }),
    );
  }

  async get(url: string, config?: any) {
    return axios.get(url, config);
  }

  async post(url: string, data: any) {
    return axios.post(url, data);
  }

  async put(url: string, data: any) {
    return axios.put(url, data);
  }

  async delete(url: string) {
    return axios.delete(url);
  }
}

export default new API();
