/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import authStore from './authStore';
import userStore from './userStore';
import UIStore from './UIStore';
import tenantStore from './tenantStore';

export default {
  authStore,
  userStore,
  UIStore,
  tenantStore,
};
