/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable */
import { observable, action, makeObservable } from 'mobx';
import tenantConfig from '../config.json';
import tenantService from '../Services/tenantService';
import Constant from '../Global/Constant';

export interface ITenantStore {
  tenantName: string,
  tenantLogo: string,
  getTenantData: any,
  tenantAccess: Array<String>,
}

class TenantStore {
  tenantName :string = '';

  tenantLogo: string = '';

  tenantAccess: Array<String> | null = null;

  constructor() {
    makeObservable(this, {
      tenantAccess: observable,
      tenantName: observable,
      tenantLogo: observable,
      getTenantData: action,
    });
  }

  getTenantData = async () => {
    try {
      this.tenantName = tenantConfig?.TENANT_NAME;
      this.tenantLogo = tenantConfig?.TENANT_LOGO;
      return (null);
    } catch (error: any) {
      return Promise.reject(Constant.defaultErrorMessage);
    }
  }

  getTenantAccess = async () => {
    try {
      const response = await tenantService.getTenantAccess();
      this.tenantAccess = response?.data?.access;
    } catch (error: any) {
      return Promise.reject(Constant.defaultErrorMessage);
    }
  }
}

export default new TenantStore();
