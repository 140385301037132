/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  observable, action, makeObservable, runInAction,
} from 'mobx';
import userService from '../Services/userService';
import Constant from '../Global/Constant';
import {
  ActivityQueryParams, ActivityResponse, ChangePassword, Employee,
} from '../Global/interface';
import authStore from './authStore';

export interface UserStoreProps {
  users: Array<Employee>,
  user: Employee,
  loggedUser: Employee,
  total: number,
  userActivity: Array<ActivityResponse>,
  activityTotal: number
  getUsers: () => void,
  createUser: () => void,
  updateUser: (id: string, user: Employee) => any,
  getUserById: (id: string) => any
  resetPassword:(password:ChangePassword)=>any
  getUserActivity: (queryParams: any) => any,
  getLoggedUser: (id: string) => any
}

class UserStore {
  users: Array<Employee> = [];

  user: Employee|null = null;

  total: number = 0;

  userActivity: Array<ActivityResponse> = []

  activityTotal: number = 0;

  loggedUser: Employee | null = null;

  access: Array<String> | null = null;

  constructor() {
    makeObservable(this, {
      users: observable,
      user: observable,
      total: observable,
      activityTotal: observable,
      userActivity: observable,
      loggedUser: observable,
      getUserActivity: action,
      getUsers: action,
      getLoggedUser: action,
      access: observable,
    });
  }

  getUsers = async (
    keyword?: string | null, pageNumber?: number, count?: number, order?: string, orderBy?: number,
    include_logged_user?: boolean,
  ) => new Promise((resolve, reject) => {
    userService.getUsers(keyword, pageNumber, count, order, orderBy, include_logged_user)
      .then((response: any) => {
        if (response && response?.data) {
          this.users = response?.data?.users?.data;
          this.total = response?.data?.users?.total[0]?.counts;
        }
        resolve(null);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  createUser = async (user: any) => new Promise((resolve, reject) => {
    userService.createUser(user)
      .then(() => {
        resolve(null);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

    updateUser = async (id: string, user: any) => new Promise((resolve, reject) => {
      userService.updateUser(id, user)
        .then(() => {
          resolve(null);
        })
        .catch((error: any) => {
          reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
        });
    })

  getUserById = async (id: string) => new Promise((resolve, reject) => {
    userService.getUserById(id)
      .then((response: any) => {
        if (response && response.data) {
          this.user = response.data.user;
        }
        resolve(null);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  resetPassword = async (password:ChangePassword) => new Promise((resolve, reject) => {
    userService.resetPassword(password)
      .then(() => {
        authStore?.signOut();
        resolve(null);
      })
      .catch((error: any) => {
        reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
      });
  })

  getUserActivity = async (
    queryParams: ActivityQueryParams,
  ) => {
    try {
      const response = await userService.getUserActivity(queryParams);
      if (response && response.data) {
        this.userActivity = response?.data?.activities;
        this.activityTotal = response?.data?.total;
      }
      return (null);
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };

  getLoggedUser = async (id: string) => {
    try {
      const response = await userService.getUserById(id);
      if (response && response?.data) {
        runInAction(() => {
          this.loggedUser = response?.data?.user;
          this.access = response?.data?.access;
        });
      }
      return (null);
    } catch (error: any) {
      return (error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  };
}

export default new UserStore();
