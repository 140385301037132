/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import Constant from '../Global/Constant';
import api from './api';

class ContactService {
  sendContactUs = async (data: any) => {
    try {
      const response = await api.post('/vms-website/contact-us', data);
      return Promise.resolve(response);
    } catch (error: any) {
      return Promise.reject(error?.response?.data?.error?.message || Constant.defaultErrorMessage);
    }
  }
}

export default new ContactService();
