/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import {
  Layout,
  Typography,
} from 'antd';
import './TermsAndConditions.less';
import { Link } from 'react-router-dom';
import Routes from '../../Global/Routes';

function TermsAndConditions() {
  return (
    <Layout.Content className="terms_bg">
      <div>
        <Typography.Title level={1} className="text-white text-center">Terms and conditions</Typography.Title>
        <div className="p_section px-3 px-sm-5">
          <Typography.Paragraph className="off-white fs-16">
            Vehicle Management Services provides vehicle storage, maintenance
            and automotive solutions to enthusiasts who seek the best products &
            services preserving and enhancing their vehicle&apos;s condition and
            motoring experience.
          </Typography.Paragraph>
          <Typography.Paragraph className="off-white fs-16">
            Vehicle Management Services provides vehicle storage, maintenance
            and automotive solutions to enthusiasts who seek the best products &
            services preserving and enhancing their vehicle&apos;s condition and
            motoring experience.
          </Typography.Paragraph>
        </div>
        <Link to={Routes.home} className="d-table m-auto">
          <div className="border-0 border-radius primary r-bold h-40 bg-white d-flex justify-content-center align-items-center px-5">Home</div>
        </Link>
      </div>
    </Layout.Content>
  );
}

export default TermsAndConditions;
