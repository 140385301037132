/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import api from './api';

class AuthService {
  login = async (data : any) => api.post('/auth/login', data);

  forgotPasswordRequest = async (email: string) => api.post('/auth/forgot-password', { email });

  resetPassword = async (data: any) => api.post('/request/changePassword', data);

  setPassword = async (data: any) => api.put('/users/set-password', data);

  syncNotificationToken = async (token: string) => api.post('/users/token-sync', { token });

  unsyncToken = async (data: any) => api.put('/users/token-unsync', data);
}

export default new AuthService();
